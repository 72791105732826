import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// Portuguese
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgModule, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT, Inject } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// [Adicionais da Aplicação]
import { QRCodeModule } from 'angularx-qrcode';
import { UseHtmlPipeModule } from '~pipes/html';
import { ReplaceEmWithStrongPipeModule } from '~pipes/replaceEmWithStrong';
import { ZipcodePipeModule } from '~project/shared/pipes/zipcode';
// [Angular]
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from 'src/project/modules/auth/guard';
// [Shared/Include/Product]
import { IncludeProductContainerModule } from '~project/shared/components/include/product/container/module';
// [Collective->Core]
import { CoreLocalizationZipcodeService } from 'src/project/services/collective/core/localization/zipcode.service';
// [Marketplace->Customer]
import { CustomerUserService } from '~services/panel/client/marketplace/customer/user/user.service';
// [Marketplace->Collective]
import { CollectiveMarketplaceService } from '~services/collective/client/marketplace/marketplace.service';
import { CollectiveLeadService } from '~services/collective/client/marketplace/lead/lead.service';
import { CollectiveMarketplaceCartService } from '~services/collective/client/marketplace/cart/cart.service';
import { CollectiveMarketplaceCoreVisualBannerService } from '~services/collective/client/marketplace/core/visual/banner/banner.service';
import { ClientMarketplaceCoreProductService } from '~services/collective/client/marketplace/core/product/product.service';
import { ClientMarketplaceCorePromotionalProductService } from '~project/services/collective/client/marketplace/core/promotional/product/promotional-product.service';

// [Marketplace->Core]
import { ClientMarketplaceCoreCategoryService } from '~services/collective/client/marketplace/core/category/category.service';
import { ClientMarketplaceCoreSalesmanService } from '~services/collective/client/marketplace/core/salesman/salesman.service';

// [[]]
import { ErrorService } from 'src/project/services/error.service';
import { CacheService } from 'src/project/services/cache.service';
import { SEO } from '~project/shared/services/SEO/service';
//[PrimeNG]
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { CarouselModule } from 'primeng/carousel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { InputMaskModule } from 'primeng/inputmask';
//
import { SlickCarouselModule } from 'ngx-slick-carousel';
//
//[]
import { enableProdMode } from '@angular/core';
enableProdMode();
// [[Layout]]
import {
  // [[Handlers]]
  HandlersComponent,
  // [[Modulo de Guard]]
  LayoutGuardComponent,
  GuardHeaderComponent,
  GuardFooterComponent,
  // []
  // [[Modulo de Panel]]
  LayoutWwwComponent,
  WwwHeaderComponent,
  WwwFooterComponent,
  // []
} from '../project/layout';
// []

@NgModule({
  declarations: [
    AppComponent,
    // [[Handlers]]
    HandlersComponent,
    // [[Layout Guard]]
    LayoutWwwComponent,
    WwwHeaderComponent,
    WwwFooterComponent,
    // []
    // [[Modulo de Www]]
    LayoutWwwComponent,
    // []
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    // x
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    CarouselModule,
    OverlayPanelModule,
    SkeletonModule,
    InputMaskModule,
    //
    SlickCarouselModule,
    //
    UseHtmlPipeModule,
    ReplaceEmWithStrongPipeModule,
    ZipcodePipeModule,
    // [Shared/Include/Product]
    IncludeProductContainerModule,
  ],
  providers: [
    AuthGuard,
    // [Collective->Core]
    CoreLocalizationZipcodeService,
    // [Marketplace->Customer]
    CustomerUserService,
    // [Marketplace->Collective]
    CollectiveMarketplaceService,
    CollectiveLeadService,
    CollectiveMarketplaceCartService,
    CollectiveMarketplaceCoreVisualBannerService,
    ClientMarketplaceCoreProductService,
    ClientMarketplaceCorePromotionalProductService,
    // [Marketplace->Core]
    ClientMarketplaceCoreCategoryService,
    ClientMarketplaceCoreSalesmanService,
    // [[]]
    ErrorService,
    CacheService,
    MessageService,
    SEO,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document) {}
}
